const product = {
    color: '#3791a2', 
    currencySymbol: 'US$',
    currencyFormat: '0,0.00',
    dateFormat: 'DD/MMM/YYYY',
    favicon: require(`../@theme/images/cb/favicon.ico`), /**require path cannot be dynamic */
    logo:  require(`../@theme/images/cb/logo.png`),  /**require path cannot be dynamic */
    title: 'CB Travel Insurance', 
    sections: [
      {title: 'POLICY DETAILS', startIndexInclusive: 0, endIndexInclusive: 10},
      {title: 'INSURED DETAILS', startIndexInclusive: 11, endIndexInclusive: 13},
      {title: 'BENEFICIARY DETAILS', startIndexInclusive: 14, endIndexInclusive: 16},
    ],
};

export default product;
