const product = { 
  color: '#4674c9',
  dateFormat: 'DD/MM/YYYY',
  favicon: require(`../@theme/images/abcInsurance/favicon.ico`), /**require path cannot be dynamic */
  logo:  require(`../@theme/images/abcInsurance/logo.png`),  /**require path cannot be dynamic */
  title: 'ABC Insurance',
  currencySymbol: 'US$',
  currencyFormat: '0,0.00',
  sections: [
    {title: 'POLICY DETAILS', startIndexInclusive: 0, endIndexInclusive: 9},
    {title: 'INSURED DETAILS', startIndexInclusive: 10, endIndexInclusive: 12},
    {title: 'BENEFICIARY DETAILS', startIndexInclusive: 13, endIndexInclusive: 15},
  ]
};

export default product;
