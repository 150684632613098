import { products } from '../@config';

const ProductsCode = {
  CBTravelInsurance: '2339001',
  CBPA: '619031',
  TravelInsuranceAlfalah: '2062582',
  CashForHospitalisation: '2036091',
  WalletInsurance: '2036273',
  MedicalInsurance:'630706',
  MotorInsurance:'629134',
  PersonalAccident:'634220',
  MyMotorInsurance:'691093',
  AutoInsurance:'686205'
};

const getProductConfig = (productCode) => {
  switch(productCode){
    case ProductsCode.CBTravelInsurance:
      return products.cb;
    case ProductsCode.CBPA:
      return products.cbPA;
    case ProductsCode.TravelInsuranceAlfalah:
      return products.alfalahTravel;
    case ProductsCode.CashForHospitalisation:
    case ProductsCode.WalletInsurance:
        return products.alfalahCashWallet;
    case ProductsCode.MedicalInsurance:
      return products.medicalInsurance;
    case ProductsCode.MotorInsurance:
      return products.motorInsurance;
    case ProductsCode.PersonalAccident:
      return products.personalAccident;
    case ProductsCode.MyMotorInsurance:
      return products.myMotorInsurance
    case ProductsCode.AutoInsurance:
      return products.autoInsurance
    default:
      return products.xc;
    
  }
};

export default getProductConfig;